import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { updateModel } from "../../services/axios";
import { toast } from "react-toastify";
import axios from "axios";

export const ModelEdit = ({ model }) => {
  const defaultValues = {
    id: model?.id,
    name: model?.name,
    quantity: model?.quantity,
    type: model?.type,
    discount: model?.discount,
    sizes:
      model?.sizes.length === 2
        ? model?.sizes[0] + "," + model.sizes[1]
        : model?.sizes[0],
    size: model?.size,
    order: model?.order,
    score: model?.score,
    description: model?.description,
    newPrice: model?.newPrice,
    oldPrice: model?.oldPrice,
    path: model?.path,
    selectedColor: model?.selectedColor ?? "",
    filterTypes: model?.filterTypes ?? "",
    filterMaterials: model?.filterMaterials ?? "",
    filterLengths: model?.filterLengths ?? "",
    // filterColors: model?.filterColors ?? "",
  };
  const { register, handleSubmit } = useForm({ defaultValues });
  const [image, setImage] = useState(model?.image);
  const [colorImages, setColorImages] = useState(model?.colorImages);
  const [colors, setColors] = useState(model?.colors);
  const [prices, setPrices] = useState(model?.prices);
  const [typeInside, setTypeInside] = useState(model?.typeInside);
  const [images, setImages] = useState(model?.images);
  const [hidden, setHidden] = useState(model?.hidden);
  const [prePay, setPrePay] = useState(model?.prePay ?? []);
  const [newColor, setNewColor] = useState(model?.newColor);

  const notify = () => toast.success("Success!");

  useEffect(() => {
    if (prePay.length === 0) {
      setPrePay(Array(model?.hidden.length).fill(false));
    }
  }, [prePay]);

  const moveUp = (index) => {
    if (index === 0) return;
    const newColorImages = [...colorImages];
    [newColorImages[index], newColorImages[index - 1]] = [
      newColorImages[index - 1],
      newColorImages[index],
    ];
    setColorImages(newColorImages);

    const newColors = [...colors];
    [newColors[index], newColors[index - 1]] = [
      newColors[index - 1],
      newColors[index],
    ];
    setColors(newColors);

    const newPrices = [...prices];
    [newPrices[index], newPrices[index - 1]] = [
      newPrices[index - 1],
      newPrices[index],
    ];
    setPrices(newPrices);

    const newColorModel = [...newColor];
    [newColorModel[index], newColorModel[index - 1]] = [
      newColorModel[index - 1],
      newColorModel[index],
    ];
    setNewColor(newColorModel);

    const newTypeInside = [...typeInside];
    [newTypeInside[index], newTypeInside[index - 1]] = [
      newTypeInside[index - 1],
      newTypeInside[index],
    ];
    setTypeInside(newTypeInside);

    const newImages = [...images];
    [newImages[index], newImages[index - 1]] = [
      newImages[index - 1],
      newImages[index],
    ];
    setImages(newImages);

    const newHidden = [...hidden];
    [newHidden[index], newHidden[index - 1]] = [
      newHidden[index - 1],
      newHidden[index],
    ];
    setHidden(newHidden);

    const newPrePay = [...prePay];
    [newPrePay[index], newPrePay[index - 1]] = [
      newPrePay[index - 1],
      newPrePay[index],
    ];
    setPrePay(newPrePay);
  };

  const moveDown = (index) => {
    if (index === hidden.length - 1) return; // Already at the bottom, cannot move down

    const newColorImages = [...colorImages];
    [newColorImages[index], newColorImages[index + 1]] = [
      newColorImages[index + 1],
      newColorImages[index],
    ];
    setColorImages(newColorImages);

    const newColors = [...colors];
    [newColors[index], newColors[index + 1]] = [
      newColors[index + 1],
      newColors[index],
    ];
    setColors(newColors);

    const newColorModel = [...newColor];
    [newColorModel[index], newColorModel[index + 1]] = [
      newColorModel[index + 1],
      newColorModel[index],
    ];
    setNewColor(newColorModel);

    const newPrices = [...prices];
    [newPrices[index], newPrices[index + 1]] = [
      newPrices[index + 1],
      newPrices[index],
    ];
    setPrices(newPrices);

    const newTypeInside = [...typeInside];
    [newTypeInside[index], newTypeInside[index + 1]] = [
      newTypeInside[index + 1],
      newTypeInside[index],
    ];
    setTypeInside(newTypeInside);

    const newImages = [...images];
    [newImages[index], newImages[index + 1]] = [
      newImages[index + 1],
      newImages[index],
    ];
    setImages(newImages);

    const newHidden = [...hidden];
    [newHidden[index], newHidden[index + 1]] = [
      newHidden[index + 1],
      newHidden[index],
    ];
    setHidden(newHidden);

    const newPrePay = [...prePay];
    [newPrePay[index], newPrePay[index + 1]] = [
      newPrePay[index + 1],
      newPrePay[index],
    ];
    setPrePay(newPrePay);
  };

  const onAddColorButtonClick = () => {
    setHidden((prev) => [...prev, true]);
    setPrePay((prev) => [...prev, false]);
    setColorImages((prev) => [...prev, ""]);
    setColors((prev) => [...prev, ""]);
    setPrices((prev) => [...prev, ""]);
    setTypeInside((prev) => [...prev, ""]);
    setNewColor((prev) => [...prev, false]);
    setImages((prev) => [...prev, [{ url: "" }, { url: "" }]]);
  };

  const onPricesInputChange = (e, index) => {
    setPrices((prev) => {
      const arr = [...prev];
      const elToAdd = e.target.value;
      arr.splice(index, 1, elToAdd);
      return arr;
    });
  };

  const onNewColorButtonClick = (index) => {
    setNewColor((prev) => {
      const arr = [...prev];
      const elToAdd = !arr[index];
      arr.splice(index, 1, elToAdd);
      return arr;
    });
  };

  const onTypeInsideInputChange = (e, index) => {
    setTypeInside((prev) => {
      const arr = [...prev];
      const elToAdd = e.target.value;
      arr.splice(index, 1, elToAdd);
      return arr;
    });
  };

  const onColorInputChange = (e, index) => {
    setColors((prev) => {
      const arr = [...prev];
      const elToAdd = e.target.value;
      arr.splice(index, 1, elToAdd);
      return arr;
    });
  };

  const onHideButtonClick = (index) => {
    setHidden((prev) => {
      const arr = [...prev];
      const elToAdd = !arr[index];
      arr.splice(index, 1, elToAdd);
      return arr;
    });
  };

  const onPrePayButtonClick = (index) => {
    setPrePay((prev) => {
      const arr = [...prev];
      const elToAdd = !arr[index];
      arr.splice(index, 1, elToAdd);
      return arr;
    });
  };

  const onDeleteButtonClick = (index) => {
    setHidden((prev) => {
      const arr = [...prev];
      arr.splice(index, 1);
      return arr;
    });

    setPrePay((prev) => {
      const arr = [...prev];
      arr.splice(index, 1);
      return arr;
    });

    setColorImages((prev) => {
      const arr = [...prev];
      arr.splice(index, 1);
      return arr;
    });

    setColors((prev) => {
      const arr = [...prev];
      arr.splice(index, 1);
      return arr;
    });

    setPrices((prev) => {
      const arr = [...prev];
      arr.splice(index, 1);
      return arr;
    });

    setTypeInside((prev) => {
      const arr = [...prev];
      arr.splice(index, 1);
      return arr;
    });

    setNewColor((prev) => {
      const arr = [...prev];
      arr.splice(index, 1);
      return arr;
    });

    setImages((prev) => {
      const arr = [...prev];
      arr.splice(index, 1);
      return arr;
    });
  };

  const getPreviewBuffer = async (file) => {
    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await axios.post(
        `https://princesss.store/api/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setImage(response.data.imageUrl);
    } catch (error) {
      console.error("Error uploading the file:", error);
    }
  };

  const getBuffer = async (file, index) => {
    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await axios.post(
        `https://princesss.store/api/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setColorImages((prev) => {
        const arr = [...prev];
        arr[index] = response.data.imageUrl;
        return arr;
      });
    } catch (error) {
      console.error("Error uploading the file:", error);
    }
  };

  const getBufferImage = async (file, index, newIndex) => {
    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await axios.post(
        `https://princesss.store/api/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setImages((prev) => {
        const arr = [...prev];
        if (newIndex === 2 || newIndex === 3 || newIndex === 4) {
          arr[index][newIndex] = { url: response.data.imageUrl };
        } else {
          arr[index][newIndex].url = response.data.imageUrl;
        }
        return arr;
      });
    } catch (error) {
      console.error("Error uploading the file:", error);
    }
  };

  const onFormSubmit = (data) => {
    if (data.sizes.includes(",")) {
      data.sizes = data.sizes.split(",");
    } else {
      data.sizes = [data.sizes];
    }
    data.selectedColor = +data.selectedColor;
    data.order = +data.order;

    updateModel(
      {
        ...data,
        _id: model._id,
        colors,
        prices,
        typeInside,
        newColor,
        hidden,
        prePay,
      },
      image,
      images,
      colorImages
    );

    notify();
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} className="panel__form">
      <div className="panel__input--wrapper">
        <input
          type="text"
          className="model__panel--input"
          {...register("id", { required: true })}
          placeholder="id"
        />
        <input
          type="text"
          className="model__panel--input"
          {...register("name", { required: true })}
          placeholder="name"
        />
        <input
          type="text"
          className="model__panel--input"
          {...register("quantity", { required: true })}
          placeholder="quantity"
        />
        <input
          type="text"
          className="model__panel--input"
          {...register("type", { required: true })}
          placeholder="type"
        />
        <input
          type="text"
          className="model__panel--input"
          {...register("discount", { required: true })}
          placeholder="discount"
        />
        <div style={{ position: "relative" }}>
          <input
            type="file"
            className="model__panel--input"
            style={{ opacity: 0, zIndex: "111" }}
            onChange={(e) => {
              getPreviewBuffer(e.target.files[0]);
            }}
          />
          {image ? (
            <div className="model__panel--file" style={{ width: "250px" }}>
              UPLOADED✅
              <img
                src={image}
                alt="preview"
                className="panel__image--preview"
              />
            </div>
          ) : (
            <div className="model__panel--file">preview image</div>
          )}
        </div>
        <input
          type="text"
          className="model__panel--input"
          {...register("sizes", { required: true })}
          placeholder="Size,Length"
        />
        <input
          type="text"
          className="model__panel--input"
          {...register("size", { required: true })}
          placeholder="size"
        />
        <input
          type="text"
          className="model__panel--input"
          {...register("order", { required: true })}
          placeholder="order"
        />
        <input
          type="text"
          className="model__panel--input"
          {...register("score", { required: true })}
          placeholder="score"
        />
        <input
          type="text"
          className="model__panel--input"
          {...register("description", { required: true })}
          placeholder="description"
        />
        <input
          type="text"
          className="model__panel--input"
          {...register("newPrice", { required: true })}
          placeholder="new price"
        />
        <input
          type="text"
          className="model__panel--input"
          {...register("oldPrice", { required: true })}
          placeholder="old price"
        />
        <input
          type="text"
          className="model__panel--input"
          {...register("path", { required: true })}
          placeholder="path"
        />
        <input
          type="text"
          className="model__panel--input"
          {...register("selectedColor", { required: true })}
          placeholder="start color"
        />
        <input
          type="text"
          className="model__panel--input"
          {...register("filterTypes", { required: true })}
          placeholder="Фільтр тип"
        />
        <input
          type="text"
          className="model__panel--input"
          {...register("filterMaterials", { required: true })}
          placeholder="Фільтр матеріал"
        />
        <input
          type="text"
          className="model__panel--input"
          {...register("filterLengths", { required: true })}
          placeholder="Фільтр довжина"
        />
        {/* <input
          type="text"
          className="model__panel--input"
          {...register("filterColors", { required: true })}
          placeholder="Фільтр колір"
        /> */}
      </div>
      {hidden?.map((item, index) => (
        <div
          key={index}
          className="panel__input--wrapper"
          style={{
            paddingTop: "20px",
            marginTop: "20px",
            borderTop: "2px solid #fcb25f",
          }}
        >
          <button
            type="button"
            onClick={() => {
              moveUp(index);
            }}
            className="panel__up--button"
          >
            <svg
              width="27"
              height="16"
              viewBox="0 0 27 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.292892 7.29289C-0.0976315 7.68342 -0.0976315 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM27 7L1 7V9L27 9V7Z"
                fill="#fcb25f"
              />
            </svg>
          </button>
          <button
            type="button"
            onClick={() => {
              moveDown(index);
            }}
            className="panel__down--button"
          >
            <svg
              width="27"
              height="16"
              viewBox="0 0 27 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.292892 7.29289C-0.0976315 7.68342 -0.0976315 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM27 7L1 7V9L27 9V7Z"
                fill="#fcb25f"
              />
            </svg>
          </button>
          <div style={{ position: "relative" }}>
            <input
              type="file"
              onChange={(e) => {
                getBuffer(e.target.files[0], index);
              }}
              style={{ opacity: 0, zIndex: "111" }}
              className="model__panel--input"
            />
            {colorImages[index] ? (
              <div className="model__panel--file" style={{ width: "250px" }}>
                UPLOADED✅
                <img
                  src={colorImages[index]}
                  alt="preview"
                  className="panel__image--preview"
                />
              </div>
            ) : (
              <div className="model__panel--file">Color image</div>
            )}
          </div>
          <button
            type="button"
            className="model__panel--input"
            style={
              newColor[index]
                ? {
                    width: "180px",
                    border: "2px solid #fcb25f",
                    backgroundColor: "#fcb25f",
                    color: "#22252b",
                  }
                : {
                    width: "180px",
                    border: "2px solid rgb(93 93 93)",
                    backgroundColor: "rgb(189, 193, 198)",
                    color: "rgb(34, 37, 43)",
                  }
            }
            onClick={() => {
              onNewColorButtonClick(index);
            }}
          >
            {newColor[index] ? "New now" : "Default now"}
          </button>
          <input
            type="text"
            placeholder="color"
            value={colors[index]}
            onChange={(e) => {
              onColorInputChange(e, index);
            }}
            className="model__panel--input"
          />
          <input
            type="text"
            placeholder="price"
            value={prices[index]}
            onChange={(e) => {
              onPricesInputChange(e, index);
            }}
            className="model__panel--input"
          />
          <input
            type="text"
            placeholder="type"
            value={typeInside[index]}
            onChange={(e) => {
              onTypeInsideInputChange(e, index);
            }}
            className="model__panel--input"
          />
          <div style={{ position: "relative" }}>
            <input
              type="file"
              onChange={(e) => {
                getBufferImage(e.target.files[0], index, 0);
              }}
              className="model__panel--input"
              style={{ opacity: 0, zIndex: "111" }}
            />
            {images[index][0]?.url !== "" ? (
              <div className="model__panel--file" style={{ width: "250px" }}>
                UPLOADED✅
                <img
                  src={images[index][0]?.url}
                  alt="preview"
                  className="panel__image--preview"
                />
              </div>
            ) : (
              <div className="model__panel--file">First image</div>
            )}
          </div>
          <div style={{ position: "relative" }}>
            <input
              type="file"
              onChange={(e) => {
                getBufferImage(e.target.files[0], index, 1);
              }}
              className="model__panel--input"
              style={{ opacity: 0, zIndex: "111" }}
            />
            {images[index][1]?.url !== "" ? (
              <div className="model__panel--file" style={{ width: "250px" }}>
                UPLOADED✅
                <img
                  src={images[index][1]?.url}
                  alt="preview"
                  className="panel__image--preview"
                />
              </div>
            ) : (
              <div className="model__panel--file">Second image</div>
            )}
          </div>
          <div style={{ position: "relative" }}>
            <input
              type="file"
              onChange={(e) => {
                getBufferImage(e.target.files[0], index, 2);
              }}
              className="model__panel--input"
              style={{ opacity: 0, zIndex: "111" }}
            />
            {images[index][2] ? (
              <div className="model__panel--file" style={{ width: "250px" }}>
                UPLOADED✅
                <img
                  src={images[index][2]?.url}
                  alt="preview"
                  className="panel__image--preview"
                />
              </div>
            ) : (
              <div className="model__panel--file">Third image</div>
            )}
          </div>
          <div style={{ position: "relative" }}>
            <input
              type="file"
              onChange={(e) => {
                getBufferImage(e.target.files[0], index, 3);
              }}
              className="model__panel--input"
              style={{ opacity: 0, zIndex: "111" }}
            />
            {images[index][3] ? (
              <div className="model__panel--file" style={{ width: "250px" }}>
                UPLOADED✅
                <img
                  src={images[index][3]?.url}
                  alt="preview"
                  className="panel__image--preview"
                />
              </div>
            ) : (
              <div className="model__panel--file">Fourth image</div>
            )}
          </div>
          <div style={{ position: "relative" }}>
            <input
              type="file"
              onChange={(e) => {
                getBufferImage(e.target.files[0], index, 4);
              }}
              className="model__panel--input"
              style={{ opacity: 0, zIndex: "111" }}
            />
            {images[index][4] ? (
              <div className="model__panel--file" style={{ width: "250px" }}>
                UPLOADED✅
                <img
                  src={images[index][4]?.url}
                  alt="preview"
                  className="panel__image--preview"
                />
              </div>
            ) : (
              <div className="model__panel--file">Fifth image</div>
            )}
          </div>
          <button
            type="button"
            className="model__panel--input"
            style={
              hidden[index]
                ? {
                    width: "205px",
                    border: "2px solid #fcb25f",
                    backgroundColor: "#fcb25f",
                    color: "#22252b",
                  }
                : {
                    width: "205px",
                    border: "2px solid rgb(93 93 93)",
                    backgroundColor: "rgb(189, 193, 198)",
                    color: "rgb(34, 37, 43)",
                  }
            }
            onClick={() => {
              onHideButtonClick(index);
            }}
          >
            {hidden[index] ? "Active NOW" : "Hidden NOW"}
          </button>
          <button
            type="button"
            className="model__panel--input"
            style={
              prePay[index]
                ? {
                    width: "205px",
                    border: "2px solid #fcb25f",
                    backgroundColor: "#fcb25f",
                    color: "#22252b",
                  }
                : {
                    width: "205px",
                    border: "2px solid rgb(93 93 93)",
                    backgroundColor: "rgb(189, 193, 198)",
                    color: "rgb(34, 37, 43)",
                  }
            }
            onClick={() => {
              onPrePayButtonClick(index);
            }}
          >
            {prePay[index] ? "Передоплата" : "Без передоплати"}
          </button>
          <button
            type="button"
            className="model__panel--input"
            style={{
              width: "215px",
              border: "2px solid #22252b",
              backgroundColor: "#da2626",
              color: "#22252b",
            }}
            onClick={() => {
              onDeleteButtonClick(index);
            }}
          >
            Delete color
          </button>
        </div>
      ))}
      <button
        type="button"
        onClick={onAddColorButtonClick}
        className="model__panel--button"
        style={{ margin: "25px auto 0" }}
      >
        Add color
      </button>
      <button
        type="submit"
        className="model__panel--button"
        style={{ margin: "25px auto 0" }}
      >
        Save
      </button>
    </form>
  );
};
