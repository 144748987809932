import { Data } from "./Data/Data";
import { Contact } from "./Contact/Contact";
import { useState } from "react";

export const Cart = ({ setCartOpen }) => {
  const [contact, setContact] = useState(false);
  return (
    <div className="present__backdrop">
      {contact ? (
        <Contact setContact={setContact} />
      ) : (
        <Data setContact={setContact} setCartOpen={setCartOpen} />
      )}
    </div>
  );
};
