import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  createNewSection,
  createReview,
  updateReview,
} from "../../services/axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

export const ReviewEdit = ({ review }) => {
  const defaultValues = {
    name: review?.name ?? "",
    description: review?.description ?? "",
    text: review?.text ?? "",
  };
  const { register, handleSubmit } = useForm({ defaultValues });
  const [image, setImage] = useState(review?.image ?? "");
  const [hidden, setHidden] = useState(review?.hidden ?? false);

  const notify = () => toast.success("Success!");

  const getPreviewBuffer = async (file) => {
    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await axios.post(
        `https://princesss.store/api/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setImage(response.data.imageUrl);
    } catch (error) {
      console.error("Error uploading the file:", error);
    }
  };

  const onFormSubmit = (data) => {
    updateReview({ ...data, _id: review?._id, hidden, image });

    notify();
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} className="panel__form">
      <div className="panel__input--wrapper">
        <input
          type="text"
          className="model__panel--input"
          {...register("name", { required: true })}
          placeholder="name"
        />
        <div style={{ position: "relative" }}>
          <input
            type="file"
            className="model__panel--input"
            style={{ opacity: 0, zIndex: "111" }}
            onChange={(e) => {
              getPreviewBuffer(e.target.files[0]);
            }}
          />
          {image ? (
            <div className="model__panel--file" style={{ width: "250px" }}>
              UPLOADED✅
              <img
                src={image}
                alt="preview"
                className="panel__image--preview"
              />
            </div>
          ) : (
            <div className="model__panel--file">preview image</div>
          )}
        </div>
        <input
          type="text"
          className="model__panel--input"
          {...register("description", { required: true })}
          placeholder="description"
        />
        <input
          type="text"
          className="model__panel--input"
          {...register("text", { required: true })}
          placeholder="text"
        />
        <button
          type="button"
          className="model__panel--button"
          onClick={() => {
            setHidden((prev) => !prev);
          }}
          style={
            hidden
              ? {
                  width: "180px",
                  border: "2px solid rgb(93 93 93)",
                  backgroundColor: "rgb(189, 193, 198)",
                  color: "rgb(34, 37, 43)",
                }
              : {
                  width: "180px",
                  border: "2px solid #fcb25f",
                  backgroundColor: "#fcb25f",
                  color: "#22252b",
                }
          }
        >
          {hidden ? "Hidden" : "Active"}
        </button>
      </div>
      <button
        type="submit"
        className="model__panel--button"
        style={{ margin: "25px auto 0" }}
      >
        Save
      </button>
    </form>
  );
};
