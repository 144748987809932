import slideBack from "../../../assets/images/slideBack.png";
import arrow from "../../../assets/images/arrowUp.png";
import NovaPoshtaInput from "../NovaPoshtaInput/NovaPoshtaInput";
import { useEffect, useState } from "react";
import {
  getCart,
  getPromocode,
  sendCallbackCart,
} from "../../../services/axios";

export const Contact = ({ setContact }) => {
  const [scroll, setScroll] = useState(false);
  const [items, setItems] = useState([]);
  const [showNova, setShowNova] = useState(false);
  const [showProducts, setShowProducts] = useState(false);
  const [total, setTotal] = useState(0);
  const [promocodeModalOpen, setPromocodeModalOpen] = useState(false);
  const [promocode, setPromocode] = useState("");
  const [error, setError] = useState(false);
  const [type, setType] = useState("");
  const [discount, setDiscount] = useState("");
  const [phone, setPhone] = useState("");
  const [bayer_name, setBayerName] = useState("");
  const [city, setCity] = useState("");
  const [branch, setBranch] = useState("");

  function isNumber(value) {
    return typeof value === "number" && !isNaN(value);
  }

  const onNameChange = (e) => {
    setBayerName(e.target.value);
  };

  const onPhoneChange = (e) => {
    if (e.target.value.trim() === "") {
      setPhone("");
    } else {
      const newValue = +e.target.value.trim();
      if (isNumber(newValue)) {
        if (
          e.target.value.length > 0 &&
          isNumber(+e.target.value[e.target.value.length - 1])
        ) {
          setPhone(e.target.value.trim());
        }
      }
    }
  };

  const onPromocodeChange = (e) => {
    setPromocode(e.target.value);
  };

  useEffect(() => {
    const fetchCart = async () => {
      const data = await getCart();
      if (data?.cart?.length > 2) {
        setScroll(true);
      }
      let sum = 0;
      for (const item of data.cart) {
        sum += item.price * item.quantity;
      }
      setTotal(sum);

      setItems(data.cart);
    };

    fetchCart();
  }, []);

  return (
    <div className="cart__modal">
      <div className="cart__top--wrapper">
        <img
          src={slideBack}
          alt="slideback"
          className="cart__contact--image"
          width={21}
          height={21}
          style={{ cursor: "pointer" }}
          onClick={() => {
            setContact(false);
          }}
        />
        <p className="cart__contact--title">контактні дані</p>
      </div>
      <div className="cart__contact--line"></div>
      <div className="cart__contact--wrapper">
        <p className="cart__contact--data">Ваше замовлення</p>
        <img
          src={arrow}
          alt="arrow"
          width={24}
          height={24}
          style={{
            cursor: "pointer",
            transform: showProducts ? "rotate(180deg)" : "rotate(360deg)",
          }}
          onClick={() => {
            setShowProducts((prev) => !prev);
            if (showNova === true) {
              setShowNova(false);
            }
          }}
        />
      </div>
      {showProducts && (
        <ul
          className="cart__list"
          style={scroll ? { overflowY: "scroll" } : {}}
        >
          {items.length === 0 ? (
            <li className="cart__text">Корзина пуста</li>
          ) : (
            items.map(({ image, name, price, color, quantity }, index) => {
              return (
                <li className="cart__item" key={index}>
                  <img src={image} alt="model" className="cart__model--image" />
                  <div className="cart__model--info">
                    <p className="cart__model--name">{name}</p>
                    <p className="cart__model--color">Обраний колір: {color}</p>
                    <div className="cart__thumb">
                      <div className="cart__quantity--wrapper">
                        <p className="cart__model--quantity">{quantity}</p>
                      </div>
                      <p className="cart__model--price">{price} ГРН</p>
                    </div>
                  </div>
                </li>
              );
            })
          )}
        </ul>
      )}
      <div
        className="cart__contact--line"
        style={{ marginBottom: "10px" }}
      ></div>
      <p className="cart__contact--total" style={{ marginBottom: "10px" }}>
        Загальна сума:
        <span className="cart__contact--total cart__total--accent">
          {type === "percentage"
            ? `${Math.ceil(
                total - (total * parseFloat(discount.replace("%", ""))) / 100
              )} грн`
            : type === "discount"
            ? `${total - discount} грн`
            : `${total} грн`}
        </span>
        {discount && (
          <>
            <span className="cart__total--old">{total}</span>
            <span className="cart__total--uah" style={{ marginLeft: "-4px" }}>
              ГРН
            </span>
          </>
        )}
      </p>
      <input
        type="text"
        placeholder="Імʼя"
        style={{ marginBottom: "12px" }}
        className="present__input cart__input"
        value={bayer_name}
        onChange={onNameChange}
      />
      <input
        type="text"
        placeholder="+38(___) ___-__-__"
        className="present__input cart__input"
        value={phone}
        onChange={onPhoneChange}
      />
      <div className="cart__contact--wrapper">
        <p className="cart__contact--info">
          Детальна інформація (необов'язково)
        </p>
        <img
          src={arrow}
          alt="arrow"
          width={24}
          height={24}
          style={{
            cursor: "pointer",
            transform: showNova ? "rotate(180deg)" : "rotate(360deg)",
          }}
          onClick={() => {
            setShowNova((prev) => !prev);
            if (showProducts === true) {
              setShowProducts(false);
            }
          }}
        />
      </div>
      <NovaPoshtaInput
        showNova={showNova}
        city={city}
        setCity={setCity}
        branch={branch}
        setBranch={setBranch}
      />
      <div className="cart__buttons--wrapper">
        <div
          className="cart__contact--line"
          style={{ marginBottom: "12px", width: "100%" }}
        ></div>
        <button
          type="button"
          className="cart__bottom--button cart__promocode--button"
          style={
            type
              ? {
                  background:
                    "linear-gradient(90deg, #1BA339 0%, #94FF91 100%)",
                }
              : {}
          }
          onClick={() => {
            if (!type) {
              setPromocodeModalOpen(true);
            }
          }}
        >
          {type === "delivery"
            ? "Безкоштовна доставка застосована!"
            : type === "percentage"
            ? `Застосована знижка ${discount}`
            : type === "discount"
            ? `Застосована знижка ${discount} грн`
            : "Маєте промокод ?"}
        </button>
        <button
          type="button"
          className="cart__bottom--button"
          onClick={() => {
            const products = [];
            let colors = "";
            for (const item of items) {
              colors = colors + " " + item.color + " " + item.name + ";";
              products.push({
                product_id: item.id,
                price: item.price,
                count: item.quantity,
              });
            }
            localStorage.setItem("thanksBayerName", bayer_name);
            localStorage.setItem("thanksPhoneNumber", phone);
            if (city) {
              localStorage.setItem("thanksCity", city);
            }

            if (branch) {
              localStorage.setItem("thanksBranch", branch);
            }

            sendCallbackCart(
              products,
              phone,
              bayer_name,
              `${colors}, місто: ${city ? city : ""}, відділення: ${
                branch ? branch : ""
              }, ${
                type === "delivery"
                  ? `безкоштовна доставка, ціна ${discount}`
                  : type === "discount"
                  ? `знижка ${discount}, ціна ${total - discount}`
                  : type === "percentage"
                  ? `знижка ${discount}, ціна ${Math.ceil(
                      total -
                        (total * parseFloat(discount.replace("%", ""))) / 100
                    )}`
                  : `ціна ${total}`
              }`
            );
            if (type === "delivery") {
              localStorage.setItem("totalCartPrice", `${total} грн`);
            } else if (type === "discount") {
              localStorage.setItem("totalCartPrice", `${total - discount} грн`);
            } else if (type === "percentage") {
              localStorage.setItem(
                "totalCartPrice",
                `${Math.ceil(
                  total - (total * parseFloat(discount.replace("%", ""))) / 100
                )} грн`
              );
            }
            setTimeout(() => {
              window.location.href = "/thanks";
            }, 600);
          }}
        >
          Оформити замовлення
        </button>
      </div>
      {promocodeModalOpen && (
        <div
          className="cart__promocode--backdrop"
          id="backdropPromo"
          onClick={(e) => {
            if (e.target.id === "backdropPromo") {
              setPromocodeModalOpen(false);
            }
          }}
        >
          <div className="cart__promocode--modal">
            <div className="cart__promocode--thumb">
              <p className="cart__promocode--title">Промокод</p>
              <svg
                onClick={() => {
                  setPromocodeModalOpen(false);
                }}
                style={{ cursor: "pointer" }}
                className="cart__promocode--cross"
                width="24"
                height="24"
                viewBox="0 0 33 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.5 2.75C24.1038 2.75 30.25 8.89625 30.25 16.5C30.25 24.1038 24.1038 30.25 16.5 30.25C8.89625 30.25 2.75 24.1038 2.75 16.5C2.75 8.89625 8.89625 2.75 16.5 2.75ZM21.4363 9.625L16.5 14.5613L11.5637 9.625L9.625 11.5637L14.5613 16.5L9.625 21.4363L11.5637 23.375L16.5 18.4387L21.4363 23.375L23.375 21.4363L18.4387 16.5L23.375 11.5637L21.4363 9.625Z"
                  fill="#292D34"
                />
              </svg>
            </div>
            <div className="cart__contact--line"></div>
            <p className="cart__promo--text">
              Введіть у цьому полі код сертифіката або спеціальний промокод для
              отримання знижки або подарунка
            </p>
            {error === true ? (
              <p
                className="cart__promocode--title"
                style={{ color: "#f60205" }}
              >
                Промокода не існує!
              </p>
            ) : (
              error !== false && (
                <p
                  className="cart__promocode--title"
                  style={{ color: "#f60205" }}
                >
                  {error}
                </p>
              )
            )}
            <input
              type="text"
              className="present__input"
              placeholder="Промокод"
              value={promocode}
              onChange={onPromocodeChange}
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "10px auto 0",
              }}
            />
            <button
              type="button"
              className="cart__bottom--button"
              style={{ marginTop: "10px" }}
              onClick={async () => {
                if (promocode) {
                  const data = await getPromocode(promocode);
                  if (data) {
                    if (total < data.price) {
                      setError(
                        `Аби застосувати цей промокод потрібно здійснити покупку від ${data.price} грн`
                      );
                    } else {
                      setType(data.type);
                      if (data.type !== "delivery") {
                        setDiscount(data.amount);
                      }
                      setPromocodeModalOpen(false);
                    }
                  } else {
                    setError(true);
                  }
                }
              }}
            >
              Застосувати промокод
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
