import { useState } from "react";
import { useForm } from "react-hook-form";
import { updateNewSection } from "../../services/axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

export const NewEdit = ({ newSection }) => {
  const defaultValues = {
    id: newSection.id,
    name: newSection.name,
    newPrice: newSection.newPrice,
    oldPrice: newSection.oldPrice,
    score: newSection.score,
    path: newSection.path,
  };
  const { register, handleSubmit } = useForm({ defaultValues });
  const [image, setImage] = useState(newSection.image);

  const notify = () => toast.success("Success!");

  const getPreviewBuffer = async (file) => {
    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await axios.post(
        `https://princesss.store/api/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setImage(response.data.imageUrl);
    } catch (error) {
      console.error("Error uploading the file:", error);
    }
  };

  const onFormSubmit = (data) => {
    updateNewSection({ ...data, _id: newSection._id, image });

    notify();
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} className="panel__form">
      <div className="panel__input--wrapper">
        <input
          type="text"
          className="model__panel--input"
          {...register("id", { required: true })}
          placeholder="order"
        />
        <input
          type="text"
          className="model__panel--input"
          {...register("name", { required: true })}
          placeholder="name"
        />
        <div style={{ position: "relative" }}>
          <input
            type="file"
            className="model__panel--input"
            style={{ opacity: 0, zIndex: "111" }}
            onChange={(e) => {
              getPreviewBuffer(e.target.files[0]);
            }}
          />
          {image ? (
            <div className="model__panel--file" style={{ width: "250px" }}>
              UPLOADED✅
              <img
                src={image}
                alt="preview"
                className="panel__image--preview"
              />
            </div>
          ) : (
            <div className="model__panel--file">preview image</div>
          )}
        </div>
        <input
          type="text"
          className="model__panel--input"
          {...register("newPrice", { required: true })}
          placeholder="new price"
        />
        <input
          type="text"
          className="model__panel--input"
          {...register("oldPrice", { required: true })}
          placeholder="old price"
        />
        <input
          type="text"
          className="model__panel--input"
          {...register("score", { required: true })}
          placeholder="score"
        />
        <input
          type="text"
          className="model__panel--input"
          {...register("path", { required: true })}
          placeholder="path"
        />
      </div>
      <button
        type="submit"
        className="model__panel--button"
        style={{ margin: "25px auto 0" }}
      >
        Save
      </button>
    </form>
  );
};
