import { useEffect, useState } from "react";

import { ToastContainer, toast } from "react-toastify";
import {
  getAllReviews,
  getCurrentReview,
  tryLogin,
} from "../../services/axios";
import { ReviewCreate } from "../../components/ReviewCreate/ReviewCreate";
import { ReviewEdit } from "../../components/ReviewEdit/ReviewEdit";

export const ReviewsPanel = () => {
  const [page, setPage] = useState("base");
  const [reviews, setReviews] = useState([]);
  const [review, setReview] = useState(null);

  useEffect(() => {
    const tryGetAccess = async () => {
      const password = localStorage.getItem("passwordToAdminPanel");
      if (password) {
        if ((await tryLogin(password)) === "denied") {
          window.location.href = "/";
        }
      } else {
        window.location.href = "/";
      }
    };

    tryGetAccess();
  }, []);

  const notify = () => toast.success("Success!");

  useEffect(() => {
    const fetchNewSections = async () => {
      const fetchedReviews = await getAllReviews();
      // fetchedNewSections.sort((a, b) => {
      //   return parseInt(a.id) - parseInt(b.id);
      // });
      setReviews(fetchedReviews);
    };
    fetchNewSections();
  }, []);

  return (
    <div className="container" style={{ minHeight: "100vh", padding: "0" }}>
      {page === "base" ? (
        <>
          <button
            type="button"
            className="model__panel--button"
            onClick={() => {
              setPage("create");
            }}
          >
            Створити
          </button>
          <div className="panel__list">
            {reviews?.map(({ _id, image, name, hidden }) => (
              <div className="panel__item--wrapper" key={_id}>
                <div
                  style={
                    hidden
                      ? {
                          backgroundColor: "rgb(189, 193, 198)",
                          border: "rgb(93, 93, 93)",
                        }
                      : { backgroundColor: "#fcb25f" }
                  }
                  key={_id}
                  className="panel__item"
                  onClick={async () => {
                    setReview(await getCurrentReview(_id));
                    setPage("edit");
                  }}
                >
                  <img src={image} alt={name} className="panel__image" />
                  <p className="panel__name">{name}</p>
                </div>
                {/* <div
                  className="panel__button--hide"
                  // onClick={() => {
                  //   hideNewSection(_id, !show);
                  //   notify();
                  // }}
                >
                  {hidden ? "Hidden" : "Active"}
                </div> */}
              </div>
            ))}
          </div>
        </>
      ) : page === "create" ? (
        <ReviewCreate />
      ) : (
        <ReviewEdit review={review} />
      )}
      <ToastContainer />
    </div>
  );
};
