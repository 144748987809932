import React, { useState } from "react";

const NovaPoshtaInput = ({ showNova, city, setCity, branch, setBranch }) => {
  const [selectedCity, setSelectedCity] = useState(null);
  const [cities, setCities] = useState([]);
  const [branches, setBranches] = useState([]);
  const [filteredBranches, setFilteredBranches] = useState([]);

  const fetchCities = async (searchQuery) => {
    const response = await fetch("https://api.novaposhta.ua/v2.0/json/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        apiKey: "4034bbdaa475617906bcb0cd6468ede9",
        modelName: "Address",
        calledMethod: "getCities",
        methodProperties: {
          FindByString: searchQuery,
        },
      }),
    });

    const data = await response.json();
    setCities(data.data);
  };

  const fetchBranches = async (cityRef) => {
    const response = await fetch("https://api.novaposhta.ua/v2.0/json/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        apiKey: "4034bbdaa475617906bcb0cd6468ede9",
        modelName: "Address",
        calledMethod: "getWarehouses",
        methodProperties: {
          CityRef: cityRef,
        },
      }),
    });

    const data = await response.json();
    setBranches(data.data);
  };
  const handleCityChange = (e) => {
    setBranch("");
    setCity(e.target.value);
    setSelectedCity(null);
    setBranches([]);
    setFilteredBranches([]);
    if (e.target.value.length > 2) {
      fetchCities(e.target.value);
    } else {
      setCities([]);
    }
  };

  const handleCitySelect = (city) => {
    setCity(city.Description);
    setSelectedCity(city);
    setCities([]);
    fetchBranches(city.Ref);
  };

  const handleBranchChange = (e) => {
    const inputValue = e.target.value;
    setBranch(inputValue);

    const filtered = branches.filter((branch) =>
      branch.Description.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredBranches(filtered);
  };

  const handleBranchSelect = (branch) => {
    setBranch(branch.Description);
    setFilteredBranches([]);
  };

  return (
    <div className={showNova ? "nova-poshta-input" : "visually__hidden"}>
      <label>
        <input
          type="text"
          value={city}
          style={{ marginBottom: 0 }}
          onChange={handleCityChange}
          placeholder="Населений пункт"
          className="present__input cart__input"
        />
        {cities.length > 0 && (
          <ul className="suggestions-list">
            {cities.map((city) => (
              <li
                key={city.Ref}
                onClick={() => handleCitySelect(city)}
                className="suggestion-item"
              >
                {city.Description}
              </li>
            ))}
          </ul>
        )}
      </label>

      {selectedCity && (
        <label>
          <input
            style={{ marginTop: "12px", marginBottom: 0 }}
            type="text"
            value={branch}
            onChange={handleBranchChange}
            placeholder="Номер відділення Нова Пошта"
            className="present__input cart__input"
          />
          {filteredBranches.length > 0 && (
            <ul className="suggestions-list">
              {filteredBranches.map((branch) => (
                <li
                  key={branch.Ref}
                  onClick={() => handleBranchSelect(branch)}
                  className="suggestion-item"
                >
                  {branch.Description}
                </li>
              ))}
            </ul>
          )}
        </label>
      )}
    </div>
  );
};

export default NovaPoshtaInput;
